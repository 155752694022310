import api from './index'
import authHeader from './auth-header'

class serviceReport {

    getStaffReport(formData) {
        return api.post('admin/report/staff', formData, { headers: authHeader() })
    }

    getCaseReport(formData) {
        return api.post('admin/report/case', formData, { headers: authHeader() })
    }

}

export default new serviceReport()